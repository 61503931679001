<template>
<div>
  <div class="full-width hero">
    <div class="col-12 f-screen-height">
      <div class="no-mobi">
        <br> <br> <br> <br> <br> <br> <br> <br> <br>
      </div>
      <h1>Non trovi quello che cerchi?</h1>
      <router-link to="/" class="button">Torna ad acquistare</router-link>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'NotFound',
  mounted () {}
}
</script>

<style scoped>
.f-screen-height {
  text-align: center;
}
</style>



